@import 'common/styles/global';

.user-picture {
  background: url('/common/assets/picture.svg') no-repeat center center;
  background-size: cover;
  border-radius: 50%;
  display: flex;
  position: relative;
  min-height: 100%;
  border-radius: 50%;
  width: 100%;
  height: 100%;
}
.user-picture-img {
  background-size: cover;
  border-radius: 50%;
  min-height: 100%;
  border-radius: 50%;
  width: 100% ;
  height: 100% ;
  margin: 0;
  object-fit: cover;
}
