@import 'common/styles/global';
@import 'common/styles/_zindex';

.category-navigation {
 


  .breadcrumb {
    display: flex;
    align-items: center;
    position: relative;
    padding: 0 1em;
    margin-right: 1em;
    letter-spacing: 1.25px;
    font-size: 12px;

    &::after {
      content: '';
      right: 0;
      position: absolute;
      height: 70%;
      width: 1px;
      background-color: rgba(0, 0, 0, 0.12);
    }
  }

  &--no-subcategories .breadcrumb {
    margin: 0;
    padding: 0;
  }

  &--no-subcategories .breadcrumb::after {
    display: none;
  }

  &__content {
    flex: 1 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
  }

  &--scroll-down {
    display: none;
  }

  .swiper-container {
    width: 100%;
  }

  .swiper-slide {
    display: flex;
    width: auto;
  }

  &--locked {
    .swiper-wrapper {
      width: auto;
      display: flex;
      justify-content: center;
    }
  }

  @media screen and (min-width: $tablet-wide) {
    top: $navbar-desktop-height;

    &__subcategories-container {
      justify-content: center;
    }

    &::before {
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
}


