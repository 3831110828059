:root {
  --color-Neutral-1: #ffffff;
  --color-Neutral-2: #edefed;
  --color-Neutral-3: #c2ced1;
  --color-Neutral-4: #4c626d;
  --color-Neutral-5: #0f2834;
  --color-Neutral-6: #050f14;
  --color-modal-background: #ffffff;
  --color-surface-1: #e8ecff;

  --color-primary-low: #9db7f9;
  --color-primary-pure: #0027cc;
  --color-primary-high: #061989;
  --action-background-primary-color: #0027cc;

  --color-brand-01: #e8ecff;
  --color-brand-02: #9db7f9;
  --color-brand-03: #1836f5;
  --color-brand-04: #0027cc;
  --color-brand-05: #061989;
  --color-brand-06: #12113a;

  --color-positive-1: #e5f5eb;
  --color-positive-2: #b2e0c4;
  --color-positive-3: #33ac61;
  --color-positive-4: #00973a;
  --color-positive-5: #005b23;
  --color-positive-6: #003c17;

  --color-warning-1: #fef4e8;
  --color-warning-2: #fddfba;
  --color-warning-3: #fabe76;
  --color-warning-4: #f7931a;
  --color-warning-5: #945810;
  --color-warning-6: #633b0a;

  --color-negative-1: #fbe9e7;
  --color-negative-2: #f4bcb6;
  --color-negative-3: #ea796d;
  --color-negative-4: #dc1f0b;
  --color-negative-5: #841307;
  --color-negative-6: #580c04;

  --color-informative-1: #e8f4fd;
  --color-informative-2: #b9defa;
  --color-informative-3: #73bdf4;
  --color-informative-4: #1591ed;
  --color-informative-5: #0d578e;
  --color-informative-6: #083a5f;

  --color-informative2-1: #ffeef6;
  --color-informative2-2: #ffcce5;
  --color-informative2-3: #ff98cb;
  --color-informative2-4: #ff54a8;
  --color-informative2-5: #993265;
  --color-informative2-6: #662243;

  --color-contrast-01: #ffffff;
  --color-contrast-02: #050f14;
  --color-page-error: #c10000;
  --color-chart-tooltip-background: rgba(255, 255, 255);

  --toastify-color-light: #fff;
  --toastify-color-dark: #050f14;
}
html[data-theme='dark'] {
  --color-Neutral-1: #0f2834;
  --color-Neutral-2: #050f14;
  --color-Neutral-3: #4c626d;
  --color-Neutral-4: #c2ced1;
  --color-Neutral-5: #edefed;
  --color-Neutral-6: #ffffff;
  --color-modal-background: #050f14;
  --color-surface-1: #050f14;

  --color-primary-low: #061989;
  --color-primary-pure: #9db7f9;
  --color-primary-high: #0027cc;
  --action-background-primary-color: #9db7f9;

  --color-brand-01: #e8ecff;
  --color-brand-02: #0027cc;
  --color-brand-03: #1836f5;
  --color-brand-04: #9db7f9;
  --color-brand-05: #061989;
  --color-brand-06: #12113a;

  --color-contrast-01: #050f14;
  --color-contrast-02: #ffffff;
  --color-page-error: #ff9a9a;

  --color-chart-tooltip-background: rgb(5 15 20);
}
