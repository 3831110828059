@import 'common/styles/global';

.infinite-grid {
  &__content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    width: 100%;

    &.container-wide {
      padding: 0;
    }

    
  }

  &__column,
  &__results {
    margin-right: 20px;
    max-width: 100%;

    &:last-child {
      margin-right: 0;
    }
  }

  &__results {
    width: 100%;
  }

  &__footer {
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    font-size: 16px;
    text-align: center;
  }

  &__retry,
  &__loading,
  &__placeholder {
    margin: 20px;
  }

  &__retry {
    width: 32px;
    background-image: url('/common/assets/retry-black.svg');
  }

  &__button {
    margin: 48px auto 3em;
    display: block;
    width: 90%;
    max-width: 320px;
  }
}
