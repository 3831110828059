@import 'common/styles/global';

.categories__banners {
  h1 {
    @extend %heading-sm;
    margin-bottom: 16px;
    color: var(--color-Neutral-6);
  }
  &--list {
    display: flex;
    gap: 24px;
    @media screen and (max-width: $desktop) {
      flex-direction: column;
    }
  }
  &--card {
    display: flex;
    padding: 16px;
    align-items: center;
    gap: 24px;
    flex: 1 0 0;
    border-radius: 8px;
    background: var(--color-Neutral-1);
    &__image.skeleton {
      width: 80px;
      height: 80px;
      border-radius: 100%;
    }
    &__footer {
      h3.skeleton {
        height: 20px;
        min-width: 120px;
        border-radius: 4px;
      }
      h4.skeleton {
        margin-top: 8px;
        height: 10px;
        width: 70px;
        border-radius: 4px;
      }
      h5.skeleton {
        margin-top: 8px;
        height: 15px;
        width: 90px;
        border-radius: 4px;
      }
    }

    img {
      width: 80px;
      height: 80px;
      border-radius: 100%;
      object-fit: cover;
    }
    &__image {
      height: 141px;
      position: relative;
    }
    &__footer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      h3 {
        color: var(--color-Neutral-6);
        font-size: 20px;
        font-weight: 700;
        line-height: 133%;
      }
      span {
        font-size: 14px;
        font-weight: 400;
        line-height: 160%;
        color: var(--color-Neutral-4);
      }
      .link {
        display: flex;
        gap: 8px;
        align-items: center;
        & > span {
          @extend %label-md;
          font-weight: 700;
          color: $action-label-secondary-color;
        }
      }
    }
  }
}
