@import 'common/styles/global';

.category_menu {
  .category_menu__list {
    &--open {
      position: absolute;
      width: auto;
      display: flex;
      justify-content: center;
      left: 0;
      top: 80px;
      pointer-events: all;

      @media screen and (min-width: 961px) and (max-width: 1024px) {
        top: 60px;
        padding:20px !important;
      }
    }
  }

  &__list {
    margin: 0;
    height: auto;
    background-color: var(--color-Neutral-1);
    left: 0;
    display: flex;
    gap: 24px;
    justify-content: center;
    list-style-type: none;
    padding: 40px !important;
    border-radius: 16px;
    box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.16);
  }
  &__category {
    display: flex;
    gap: 24px;
    width: 180px;
    flex-direction: column;
    font-family: $font-main;

    &__maincategory {
      margin-bottom: unset !important;
      display: flex;
      align-items: center;
      gap: 8px;
      i {
        background-color: var(--color-primary-low);
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        color: var(--color-Neutral-6);
      }
      h2 {
        @extend %label-md;
        color: var(--color-Neutral-6);
        font-size: 16px;
        font-weight: 600;
        width: 100px;
      }
      &:hover {
        color: var(--color-primary-high);
      }
    }
    &__subcategory {
      @extend %label-md;
      font-weight: 400;
      padding: 8px;
      border-radius: 4px;
      margin-left: -8px;
      color: var(--color-Neutral-6);

      &:hover {
        background-color: var(--color-Neutral-2);
        color: var(--color-Neutral-6);
      }
    }
    &__link {
      @extend %label-md;

      color: var(--color-primary-pure);
      font-weight: 600;

      &:hover {
        color: var(--color-primary-high);
        svg {
          transition: 100ms;
          margin-left: 10px;
        }
      }
    }
  }
}
