.update_request {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
  &__image {
    background-image: url('/common/assets/updateRequest.png');
    width: 37px;
    height: 37px;
  }

  &__title {
    font-weight: 400;
    font-size: 26px;
    line-height: 34px;
    color: black;
  }
  &__description {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
  }
}
