@import 'common/styles/global';

@media screen and (max-width: 640px) {
  .action-popup {
    width: 100%;
    border-radius: 0;
  }
  .mobile-bottom {
    bottom: 50px;
    z-index: 10 !important;
    transition-duration: 600ms;
    transition-timing-function: cubic-bezier(0.005, 0.75, 0.25, 1);
  }
  .hide-navbar .mobile-bottom {
    bottom: 0;
    transition-duration: 600ms;
    transition-timing-function: cubic-bezier(0.005, 0.75, 0.25, 1);
  }
  .mobile-top {
    top: 85px;
  }
  .action-popup__action {
    width: 100%;
  }
}
@media screen and (min-width: 641px) {
  .action-popup {
    width: 80%;
    border-radius: 4px;
    align-items: center;
  }
  .desktop-bottom {
    bottom: 3.75rem;
  }
  .desktop-top {
    top: 100px;
  }
  .action-popup__action {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;

    &-button {
      background: var(--color-primary-pure) !important;
      color: var(--color-Neutral-1) !important;
      padding: 0 1rem !important;
    }
  }
}

.action-popup {
  .left {
    left: 0.75rem;
  }
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  background: var(--color-Neutral-1);
  color: var(--color-Neutral-6);
  box-shadow: 0px 1px 1px rgb(0 0 0 / 7%), 0px 2px 1px rgb(0 0 0 / 6%), 0px 1px 3px rgb(0 0 0 / 10%);

  &__squareWithIcon {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    color: var(--color-primary-pure);
    background-color: white;
    display: flex;
    height: 4rem;
    justify-content: center;
    padding: 1rem;
    width: 4rem;
  }

  & &__action-button {
    padding: 0;
  }

  &__labels {
    align-items: center;
    display: flex;
    gap: 1rem;

    &--medium {
      color: var(--color-Neutral-6);
      font-family: Lato;
      font-size: 14px;
      font-style: normal;
      font-weight: bold;
      line-height: 22px;
    }

    &--small {
      color: var(--color-Neutral-6);
      font-family: Lato;
      font-size: 14px;
      font-style: normal;
      font-weight: normal;
      line-height: 18px;
    }

    &--close {
      color: var(--color-primary-pure);
      position: unset !important;
    }
  }

  &--hiding {
    opacity: 0;
    transform: translateX(-300px);
  }
}

.action-popup--container {
  position: fixed;
  width: 100%;
  z-index: 101;
  display: flex;
  justify-content: center;
}
