@import 'common/styles/global';

.currency-selector {
  color: var(--color-Neutral-6);
  height: 32px;
  display: flex;
  height: 48px;
  align-items: center;
  font-weight: 300;
  position: relative;
  border-radius: 8px;
  cursor: pointer;
  justify-content: flex-end;
  &:hover {
    background-color: var(--color-Neutral-2);
  }
  &--isOpen {
    background-color: var(--color-Neutral-2);
  }
  &--hide-price {
    opacity: 0;
  }

  &--highlight-price {
    box-shadow: none;
    a {
      color: var(--color-Neutral-1);
    }
  }

  &__icon,
  &__button {
    display: inline-block;
    vertical-align: baseline;
    position: relative;
    z-index: 10;
  }

  &__button {
    font-family: $font-main;
    color: var(--color-Neutral-6);
    font-weight: 600;
    font-size: 14px;
    line-height: 23px;
    display: flex;
    padding: 0.25em 0.75em;
    gap: 5px;
    height: 100%;
    align-items: center;
    position: relative;
    &--cervron {
      display: flex;
      align-items: center;
      transform: rotateZ(0deg);
      transition-duration: 300ms;
      &-open {
        transform: rotateZ(180deg);
      }
    }
    &--cover {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 11;
      left: 0;
      top: 0;
    }
  }

  &--light a {
    color: $color-shade-1;
  }

  &--light &__icon {
    color: $color-shade-2;
  }

  &__real-money {
    font-family: $font-main;
    font-size: 14px;
    vertical-align: middle;
    margin-right: 0.15em;
    white-space: nowrap;
  }

  &__real-money + &__icon {
    vertical-align: middle;
  }
}
