@import 'common/styles/global';

.category-results {
  .result-list {
    margin-bottom: 15px;
    gap: 24px;
  }

  &:last-child {
    .result-list {
      margin-bottom: 0;
    }
  }

  @media screen and (max-width: 767px)
  {
    padding-bottom: 32px;
  }

  .result-list {
    display: flex;
    justify-content: space-between;
    margin-bottom: 48px;
    flex-direction: column;
  }
  &__title {
    @extend %label-md;
    font-weight: 400;
    color: var(--color-Neutral-4);
    &:last-child {
      color: var(--color-Neutral-5);
      font-weight: 600;
    }
  }
  .category-list__separator{
    margin: 0 4px;
  }
}

.category-result__summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 35px;

  .summary__total {
    font-weight: $font-weight-extrabold;
    font-size: 13px;
    text-transform: uppercase;
    color: rgba($color-grey4, 0.6);
  }

  .summary__cta {
    font-weight: $font-weight-extrabold;
    font-size: 11px;
    font-family: $font-main;
    text-transform: uppercase;
    color: var(--color-primary-pure);
    cursor: pointer;
  }
}

.category-results .category {
  display: flex;
  gap: 6px;
  justify-content: flex-start;
  align-items: center;
  color: var(--color-Neutral-5);
  &__title {
    direction: rtl;
    font-weight: 800;
    font-size: 13px;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
  }

  &__icon {
    margin: 3px 10px 0px 10px;
    &:last-of-type {
      display: none;
    }
    > svg {
      height: 15px;
    }
  }
}

@media (min-width: $tablet-wide) {
  .category-results {
    background: transparent;
    border-radius: 5px;
    box-shadow: none;
    overflow: visible;
    padding: 0;
    position: relative;

    .category-result__summary {
      width: calc(100% + 50px);
      border-top: 1px solid $color-grey0;
      margin: 15px -25px 0 -25px;
      padding: 15px 25px 0;
    }
  }
}
