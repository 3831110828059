@import 'common/styles/global';

.notification-template {
  padding: 8px 1px;
  @media screen and (min-width: $desktop) {
    padding: 16px 24px;
  }
  display: flex;
  flex-direction: column;
  &--unread {
    background-color: var(--color-surface-1);
  }
  &__container {
    display: flex;
    gap: 24px;

    @media screen and (max-width: $desktop) {
      gap: 16px;
      grid-template-columns: 90px auto;
    }
  }
  &__text {
    b,
    strong {
      font-weight: 600;
    }
  }
  &__icon {
    background-color: var(--color-primary-pure);
    display: flex;
    height: 100%;
    width: 100%;
    border-radius: 100%;
    align-items: center;
    justify-content: center;
  }
  &__image {
    position: relative;
    border-radius: 100%;
    width: 56px;
    height: 56px;
    max-width: 56px;
    max-height: 56px;
    background-size: cover;
    background-position: center center;
    background-color: $color-shade-4;
    &--notRead {
      position: absolute !important;
      z-index: 1;
      right: 0;
      bottom: 0;
    }

    &__overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__title {
    font-family: $font-main;
    color: var(--color-Neutral-6);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin: 0;
    max-width: 483px;

    b,
    strong {
      font-weight: 600;
    }
    &.skeleton {
      width: 70%;
      height: 16px;
      border-radius: 8px;
    }
  }

  &__label {
    color: $color-shade-2;
    font-weight: 700;
    font-size: 12px;
    line-height: 2;
  }

  &__paragraph {
    color: var(--color-Neutral-5);

    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    &.skeleton {
      width: 90%;
      height: 60px;
      border-radius: 8px;
    }
    strong {
      color: var(--color-Neutral-5);

      font-weight: 600;
    }
  }

  &__date {
    font-size: 12px;
    line-height: 18.9px;
    color: var(--color-Neutral-4);
    font-family: $font-main;
    &.skeleton {
      width: 60%;
      height: 10px;
      border-radius: 8px;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 8px;
    width: calc(100% - 80px);
    @media screen and (max-width: $desktop) {
      // 74 = 56(image) + 1*2 (padding) + 16(gap)
      // NOTE : 100% = 100vw - 15(margin)
      max-width: calc(100% - 74px);
    }
    &__firstRow {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      justify-content: space-between;
      &--status {
        display: flex;
        gap: 1rem;
        align-items: center;
        font-size: 15px;
        font-weight: 700;
        color: black;
      }
    }
  }
}
