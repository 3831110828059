@import 'common/styles/global';

.FAQs {
  background-color: var(--color-surface-1);
  padding-bottom: 48px;
  @media screen and (max-width: $desktop) {
    padding: 0;
  }

  &__container {
    display: flex;
    width: 100%;
    background-color: var(--color-Neutral-1);
    padding: 40px 56px !important;
    border-radius: 8px;
    @media screen and (max-width: $desktop) {
      flex-direction: column;
      padding: 24px  !important;
      gap: 24px;
    }
    .accordion__description {
      margin-bottom: 8px;
    }
  }
  &__header {
    width: 40%;
    @media screen and (max-width: $desktop) {
      width: 100%;
    }
    h1 {
      @extend %heading-md;
      color: var(--color-Neutral-6);
      font-size: 24px;
      margin-bottom: 8px;

      @media screen and (max-width: $desktop) {
        // font-size: 20px;
        font-weight: 600;
        margin-bottom: 2px;
      }
    }
    a {
      @extend %paragraph-sm;
    }
  }
  &__list {
    width: 60%;
    @media screen and (max-width: $desktop) {
      width: 100%;
    }
    li:not(:first-child) {
      margin-top: 16px;
    }
    .accordion__title {
      h6 {
        @extend %heading-xxs; 
      }
      color: var(--color-Neutral-6);
    }
  }
  hr {
    border-top: 1px solid var(--color-Neutral-3);
  }
}
