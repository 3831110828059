@import 'common/styles/global';
@import 'common/styles/_zindex';

.subcategory-list {
  $padding-horizontal: $list-item-margin / 2;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  margin-left: 18px;
  &--no-slider {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    margin-right: 5px;
    white-space: nowrap;
    padding: 8px 0;
  }

  .swiper-slide:last-child {
    margin-right: 70px;
  }

  .swiper-slide:first-child {
    margin-left: 12px;
  }

  &__slider-container {
    position: relative;
    overflow: hidden;

    .swiper-container {
      mask-image: linear-gradient(to right, rgba(0, 0, 0, 1) 80%, transparent);
    }
  }

  .swiper-container {
    margin: 0;
    padding: 8px 0;
  }

  &__arrow-prev,
  &__arrow-next {
    font-size: 12px;
    position: absolute;
    width: 2.5em;
    height: 2.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
    transform: translateY(-50%);
    z-index: 5;
    display: none;

    &.swiper-button-disabled,
    &.swiper-button-hidden {
      opacity: 0;
      pointer-events: none;
    }

    &:focus {
      outline: none;
    }
  }

  &__arrow-prev,
  &__arrow-next {
    &::before {
      border-left: 2px solid var(--color-Neutral-1);
      border-top: 2px solid var(--color-Neutral-1);
      content: '';
      height: 1em;
      width: 1em;
      display: inline-block;
    }
  }

  &__arrow-prev {
    left: 0;
    margin-left: 0.25em;
    &::before {
      transform: rotateZ(-45deg);
    }
  }

  &__arrow-next {
    margin-right: 0.25em;
    right: 0;
    &::before {
      transform: rotateZ(135deg);
    }
  }

  &__name {
    align-items: center;
    color: var(--color-Neutral-1);
    display: flex;
    flex-shrink: 0;
    font-size: 18px;
    font-weight: 300;
    justify-content: center;
    width: 100%;

    &--root {
      border-right: 1px solid rgba(var(--color-Neutral-1), 0.1);
      height: 45px;
      margin-right: 0.55em;
      padding: 0 10px;
      width: auto;
      justify-content: flex-start;
    }
  }

  &__tab {
    align-items: center;
    background-color: var(--color-Neutral-1);
    border-radius: 8px;
    display: flex;
    flex: 0 0 auto;
    font-family: $font-main;
    font-size: 16px;
    box-sizing: border-box;
    font-weight: 700;
    gap: 8px;
    letter-spacing: 1.25px;
    margin-right: 12px;
    padding: 12px;
    text-transform: capitalize;
    color: var(--color-Neutral-6);
    width: auto;
    &--first {
      margin-left: 16px;
    }
    i {
      background-color: var(--color-primary-low);
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      color: var(--color-Neutral-6);
    }
    b {
      background: -webkit-linear-gradient(var(--color-brand-04), #ff5129);
      font-weight: 700;
      -webkit-background-clip: text;
      -webkit-text-fill-color: #e5414100;
    }
  }

  & .swiper-slide {
    flex: 0 0 auto;
    width: auto;
  }

  @media screen and (min-width: $tablet) {
    &__arrow-prev,
    &__arrow-next {
      display: flex;
    }
  }

  @media screen and (min-width: $tablet-wide) {
    // white-space: nowrap;
    // flex-flow: row wrap;

    &--light &__tab {
      color: rgba(var(--color-Neutral-1), 0.8);
      background-color: rgba($color-grey1, 0.1);
    }
  }
}
