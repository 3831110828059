@import 'common/styles/global';
@import 'common/styles/zindex';

.tag{
    padding: 14px 16px;
    display: block;
    background-color: var(--color-primary-low);
    border-radius: 8px;
    color: var(--color-Neutral-5);
    margin-right: 16px;
    @extend %heading-xxs;
}