@import 'common/styles/global';

.search-box {
  width: 100%;
  height: 100%;
  padding: 0px 24px;
  background-color: var(--color-Neutral-1);
  border-radius: 8px;
  display: flex;
  border: 1px solid var(--color-Neutral-3);
  color: var(--color-Neutral-6);
  justify-content: space-between;
  gap: 16px;
  transition: 200ms;
  align-items:center;
  svg {
    color: var(--color-Neutral-5);
  }
  input {
    -webkit-appearance: none;
    outline: none;
    border: none;
    color: var(--color-Neutral-6);
    height:45px;
    border-right: 1px solid var(--color-Neutral-3);
    width: 100%;
    font-size: 16px;
  }
  &:hover {
    border:1px solid var(--color-Neutral-6);
  }
  &:focus-within,
  &:focus,
  &:active {
    border: 1px solid var(--color-brand-04);
    background-color: var(--color-Neutral-1) !important;
  }
}
