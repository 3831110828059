@use 'sass:math';
@import 'common/styles/global';
@import 'common/styles/zindex';

$tabbar-offset: 8px;
%navbar-button {
  height: $navbar-height;
  padding: 0 $toolbar-padding;
  line-height: $navbar-height;
}

@keyframes tabbar-icon-animation {
  0%,
  100% {
    transform: translateY($tabbar-offset);
  }

  25%,
  75% {
    transform: translateY(0);
  }
}

@keyframes tabbar-label-animation {
  0%,
  100% {
    opacity: 0;
    transform: translateY($tabbar-offset);
  }

  25%,
  75% {
    opacity: 1;
    transform: translateY(0);
  }
}
.overAddFuns {
  position: unset !important;
  .kyc-callout {
    z-index: 2001;
  }
}
.navbar {
  background-color: var(--color-Neutral-1);
  color: var(--color-Neutral-6);
  height: 96px;
  left: 0;
  overscroll-behavior: contain;
  position: relative;
  top: 0;
  width: 100%;
  z-index: 102;
  .navbar__button {
    height: 56px !important;
  }
  &__wrap {
    width: 100%;
  }
  &--white {
    background: var(--color-Neutral-1);
    height: 50px;

    .navbar__inner {
      padding-left: math.div($list-item-margin, 2);
    }

    .navbar__icon {
      display: inline-block;
      width: 33px;
      height: 33px;
    }
  }

  &__cash-in-button {
    height: 48px !important;
  }

  &--left {
    @extend %navbar-button;
    margin-left: math.div(-$list-item-margin, 2);
  }

  &--right {
    @extend %navbar-button;
  }

  &--center &__container {
    flex-grow: 1;
    flex-shrink: 10;
    break-before: avoid;
    page-break-before: avoid;
  }

  &--with-categories {
    height: 125px;
  }

  &--logged-in &__flex-column--right {
    display: flex;
    justify-content: flex-end;
    padding-right: 64px;
  }
  @media screen and (max-width: 768px) {
    &--logged-in {
      .navbar__inner {
        grid-template-columns: 20% auto minmax(60px, max-content);
        gap: 8px;
      }
    }
  }

  &__attachment {
    $padding-horizontal: math.div($list-item-margin, 2);

    @include overflow-scrolling;
    @include hide-scrollbar;

    display: flex;
    width: 100%;
    min-height: $navbar-attachment-height;
    flex-flow: row nowrap;
    align-items: center;
    background-color: $color-black-10;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;

    &::after,
    &::before {
      display: block;
      width: $padding-horizontal;
      height: 10px;
      flex-shrink: 0;
      content: '';
    }
  }

  &__container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: var(--color-surface-1);
    // border-bottom: 1px solid $color-shade-outline;
  }
  &__search {
    height: 56px;
    width: 100%;
    position: relative;

    & .search-box {
      &:focus-within,
      &:focus,
      &:active {
        border-color: var(--color-primary-pure);
        background-color: unset;
      }
    }
  }

  &__logo {
    color: var(--color-Neutral-1);
  }

  &--overlay {
    height: 0;

    &::before {
      opacity: 0;
    }
  }

  &--overlay:not(&--is-scrolling) &__container {
    background: transparent;
  }

  &__logo,
  &__link-list {
    display: none;
  }

  &__link-text {
    display: none;
  }

  &__highlight-currency-selector {
    color: var(--color-Neutral-1);
    position: fixed;
    z-index: 10000;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
  }

  &__title {
    font-family: $font-title;
    $font-size: 18px;

    @include line-clamp($font-size, 1);

    position: relative;
    padding: 0 0 0 15px;
    font-size: $font-size;
    font-weight: bold;
    text-align: left;
    transform: translateY(1px); // Bife's OCD
  }

  &__tab {
    $height: 32px;
    background-color: $color-black-10;
    border-radius: 5px;
    color: $color-white-60;
    flex-shrink: 0;
    font-size: 13px;
    font-weight: bold;
    height: $height;
    line-height: $height;
    margin: 0 3px;
    padding: 0 10px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__icon {
    appearance: none;
    background-color: $color-white-transparent;
    background-repeat: no-repeat;
    border: 0;
    color: inherit;
    cursor: pointer;
    font: inherit;
    margin: 0;
    outline: none;
    padding: 0;

    &:active {
      opacity: 0.5;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.6;
    }
    //FIXME: END
    min-width: $navbar-height * 2 / 3;
    height: $navbar-height;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 9px;
  }

  &__user-badge {
    display: inline-block;
    margin-top: 10px;
    padding: 6px 12px;
    margin-left: 10px;
    background: $color-black-10;
    border-radius: 100px;
    color: var(--color-Neutral-1);
    font-size: 13px;
    font-weight: $font-weight-extrabold;
  }
  &__skeleton {
    display: flex;
    gap: 16px;
    order: 4;
    &--button {
      overflow-x: hidden;
      position: relative;
      user-select: auto;
      width: 150px;
      height: 48px;
      border-radius: 8px;
    }
  }
  &__notification {
    width: 48px;
    height: 48px;
    &:not(&--active) {
      cursor: pointer;
    }
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-shade-2;
    // border: 1px solid currentColor;
    overflow: visible;
    position: relative;

    @media screen and (min-width: $desktop) {
      &:hover {
        background-color: var(--color-primary-low);
      }
    }
    @media screen and (max-width: $desktop) {
      margin-right: 8px;
    }
    svg {
      position: relative;
      z-index: 5;
    }

    &--bell {
      display: flex;
    }
    &--active {
      border: 2px solid var(--color-primary-pure);
      background-color: var(--color-Neutral-1) !important;

      // &::before,
      // &::after {
      //   content: '';
      //   border-radius: 50%;
      //   position: absolute;
      //   top: 0;
      //   left: 0;
      //   background: white;

      // }

      // &::before {
      //   width: 100%;
      //   height: 100%;
      //   background: var(--color-primary-low);
      // }

      // &::after {
      //   width: calc(100% - 2px);
      //   height: calc(100% - 2px);
      //   left: 1px;
      //   top: 1px;
      // }

      .navbar__notification--bell {
        &--cover {
          cursor: pointer;
          top: 0;
          left: 0;
          border-radius: 100%;
          position: absolute;
          width: 100%;
          height: 100%;
          z-index: 11;
        }
        path {
          fill: var(--color-primary-pure);
        }
      }
    }
  }

  &__notification-warning {
    position: absolute;
    height: 12px;
    width: 12px;
    right: 11px;
    top: 8px;
    background-color: $color-error;
    border-radius: 50%;
    border: 2px solid var(--color-Neutral-1);
    z-index: 10;
  }

  &--scrolling {
    &::before {
      opacity: 1;
    }
  }

  &__flex-column {
    @media screen and (max-width: $desktop -1) {
      max-width: 33vw;
    }
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    position: relative;
  }
  &__flex-column-left {
    @media screen and (max-width: 1250px) {
      position: absolute;
      z-index: 10;
    }
  }
  &__auth-button {
    @media screen and (max-width: $desktop -1) {
    }
  }
  &__back {
    background-image: url('/common/assets/back-white.svg');
    background-position: center;
    margin-right: auto;
  }

  &__inner {
    height: 96px;
    display: grid;
    width: 100%;
    grid-template-columns: 1fr auto 163px;
    @media screen and (max-width: $desktop -1) {
      grid-template-columns: 1fr auto 1fr;
    }
    grid-gap: 16px;
    align-items: center;
    padding: 0 16px;

    .user-picture {
      justify-content: center;
      align-items: center;
      img {
        height: 48px;
        width: 48px;
        min-height: unset;
      }
    }
  }

  &__button {
    height: 40px;
  }

  & &__button--fb {
    display: none;
  }

  &--logo-only {
    .navbar__link-list,
    .navbar__currency-selector,
    .navbar__notification,
    .navbar__auth-button,
    .dropdown-menu:not(.dropdown-menu--logdIn),
    .navbar__inner {
      grid-template-columns: min-content auto;
    }
  }
  &-mobile-banner {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    min-height: 45px;
    width: 100%;
    background: $categories-background;
    box-shadow: inset 0px 3px 3px -3px rgba(61, 60, 60, 0.2);

    .subcategory-list__tab {
      border-radius: 4px;
      color: var(--color-Neutral-1);
      flex-shrink: 0;
      font-size: 12px;
      font-weight: normal;
      height: 32px;
      line-height: 30px;
      margin: 0 6px;
      letter-spacing: 1.25px;
      padding: 0 10px;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  @media screen and (max-width: 960px) {
    &__flex-column {
      &--right {
        justify-content: end;
      }
    }
  }

  @media screen and (max-width: 768px) {
    &--transparent &__back {
      background-image: url('/common/assets/back-black.svg');
    }

    &--transparent .dropdown-menu__icon {
      color: $color-grey5;
    }

    .hide-navbar .navbar__flex-column--right {
      display: none;
    }

    .hide-navbar .navbar__container {
      transform: translateY(calc(-100% - 1px));
    }

    .navbar__container {
      height: 96px;
      display: flex;
      align-items: center;
      padding-right: 16px;
      padding-left: 16px;
    }
  }
  &__logo-mobile {
    font-size: 14px;
    color: var(--color-primary-pure);
  }

  @media screen and (max-width: 1250px) {
    &__link-list {
      margin-left: 40px;
    }
  }

  @media screen and (min-width: 768px) {
    &__link-list,
    &__button-list {
      display: flex;
      align-items: center;
    }

    &__link-list {
      gap: 16px;
    }

    &__link {
      margin-left: 0 !important;
      margin-right: 0 !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    &__inner {
      padding: 0;
      grid-template-columns: min-content auto minmax(60px, max-content) minmax(60px, max-content);

      .navbar--guest & {
        grid-template-columns: min-content auto minmax(60px, max-content) minmax(60px, max-content);
      }
    }
  }

  @media screen and (min-width: 768px) {
    color: var(--color-Neutral-6);

    position: relative;
    width: 100%;
    height: $navbar-desktop-height;

    &-mobile-banner {
      display: none;
      padding: 0 12px;
    }

    &__inner {
      background: transparent;
    }

    &__container {
      width: 100%;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: center;
      height: inherit;
      position: fixed;
      padding: 0 30px;
      z-index: 100;
      left: 0;
      top: 0;
      // border-bottom: 1px solid $color-shade-outline;
    }
    &__currency-selector {
      .currency-selector {
        color: currentColor;
      }
    }
    &--no-fixed &__container {
      position: relative;
      width: 100%;
    }

    &--no-fixed &__inner {
      height: $navbar-desktop-height;
    }

    &__auth-button {
      display: flex;
      gap: 16px;
    }

    &__highlight-currency-selector {
      top: 24px;
      right: 296px;
      transform: none;
      left: auto;
    }

    &__flex-column--right {
      padding-right: 64px;
      .dropdown-menu {
        padding: 0;
      }
    }

    &__link-label,
    &__title {
      display: none;
    }

    &__link-icon,
    &__link-label {
      transition: transition(250ms $notification-easing, color);
    }

    &__link-icon {
      color: currentColor;
      margin-right: 8px;
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: center;
    }

    &__link {
      display: flex;
      flex-grow: 0;
      align-items: center;
      color: var(--color-Neutral-4);
      font-weight: 400;
      font-size: 16px;
      flex-direction: column;
      text-transform: capitalize;
      position: relative;
      transition: all 100ms ease-out;
      padding: 8px;
      > svg {
        margin-bottom: 8px;
      }

      &:not(&--active):hover {
        color: var(--color-brand-04);
      }

      &.marketButton {
        position: relative;
        .marketButton__overlay {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
        }
      }
    }

    &__link--active {
      color: var(--color-primary-pure);
    }

    &__back {
      display: none;
    }

    &__balance {
      cursor: pointer;
      padding: 4px 8px;
      background-color: var(--color-primary-pure);
      border-radius: 4px;
      color: var(--color-Neutral-1);
      font-size: 13px;
      font-weight: $font-weight-extrabold;
      margin: 0 20px;
      transition: transition(250ms $notification-easing, background-color);

      &:hover {
        background-color: var(--color-primary-pure);
      }
    }

    &__button {
      &--outline {
        color: var(--color-primary-pure);
        box-shadow: inset 0px 0px 0px 1px var(--color-primary-pure);
        &:hover {
          color: var(--color-primary-pure);
          border-color: var(--color-primary-pure);
        }
      }

      &--primary {
        color: var(--color-Neutral-1);
        background-color: var(--color-primary-pure);

        &:hover {
          color: var(--color-Neutral-1);
          background-color: var(--color-primary-pure);
        }
      }
    }

    & &__button--fb {
      display: inline-block;
      padding: 8px 15px !important;
      white-space: nowrap;
      margin-right: 0 !important;
      font-size: 13px !important;
      font-weight: bold !important;
      height: auto !important;
      line-height: unset !important;
      background-size: 25px !important;
      padding-left: 25px !important;
      width: auto;
    }

    &--no-shadow &__container {
      box-shadow: none;
    }
  }

  @media screen and (max-width: $desktop) {
    &__link {
      opacity: 0;
      pointer-events: none;
      display: block;
      width: 3.5em;
      &:hover {
        transform: translateY(-5px);
      }
    }

    &__link:hover &__link-text {
      opacity: 1;
    }

    &__link-text {
      position: absolute;
      font-size: 12px;
      bottom: -20px;
      transform: translateX(-50%);
      left: 50%;
    }
  }

  @media screen and (min-width: $desktop) {
    &__link {
      margin: 0 8px;
      font-size: 16px;
      &.marketButton {
        &.navbar__link--active {
          color: var(--color-brand-04);
        }
        &:hover {
          .navbar__link-text {
            color: var(--color-brand-04);
          }
        }
        cursor: pointer;
      }
      > svg {
        @media screen and (max-width: 1070px) {
          margin-bottom: unset;
        }
      }
    }

    &__link-icon {
      color: $color-shade-3;
      font-size: 1.1em;
    }

    &__link--active &__link-icon {
      path {
        fill: var(--color-primary-low);
      }
    }

    &__link-text {
      display: flex;
      gap: 4px;
      @media screen and (max-width: 1250px) {
        opacity: 0;
        height: 0;
        width: 40px;
      }
    }

    &__link--active &__link-text {
      // color: var(--color-primary-low);
    }

    &__link:hover &__link-icon {
      color: var(--color-primary-low);
    }
  }
}

// FIXME: We are disabling the autohide feature on iOS Safari for now.
// There is some nuances with the event listener and the 'overflow-scrolling: touch' behavior.
.app--ios .navbar--hide {
  position: relative;
  transform: unset;
}

.app-ios .navbar--overlay {
  position: fixed;
}
