@import 'common/styles/global';
@import 'common/styles/zindex';

.dropdown-menu {
  &__icon {
    width: 48px;
    height: 48px;
    color: var(--color-Neutral-5);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 200ms;
    border-radius: 100%;
    border: 1px solid transparent;
    @media screen and (min-width: $desktop) {
      &:hover {
        background-color: var(--color-Neutral-2);
      }
    }
    &--arrow {
      position: absolute;
      right: -15px;
      top: 16px;
      color: #5a5a5a;
    }
    &.noBorder {
      box-sizing: content-box;
    }

    .user-picture {
      margin: 0 !important;
    }
  }

  &__item-list {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    margin-top: 10px;
    padding: 8px;
    border-radius: 16px;
    background-color: var(--color-Neutral-1);
    box-shadow: 0px 2px 8px 0px #00000026;
    z-index: 1000;
    transition: 0.5s $notification-easing opacity;
    overflow: auto;
    max-height: calc(100vh - 248px);
  }
}

.dropdown-menu--open {
  .dropdown-menu__item-list {
    opacity: 1;
    pointer-events: unset;
  }
  .dropdown-menu__icon {
    border-color: var(--color-primary-pure);
    background-color: var(--color-Neutral-1);
    color: var(--color-primary-pure);
  }
  .dropdown-menu__icon--arrow {
    transform: rotate(180deg);
  }
}

.dropdown-menu .menu-item {
  font-size: 16px;
  line-height: 21.5px;
  white-space: nowrap;
  cursor: pointer;
  padding: 16px;
  margin-bottom: 4px;
  border-radius: 4px;
  transition: 200ms;
  display: flex;
  gap: 4px;
  align-items: center;
  color: var(--color-Neutral-6);
  &:hover {
    &:not(.menu-item--noHover) {
      background-color: var(--color-Neutral-3);
    }
  }
  &:last-child {
    margin-bottom: 0;
  }

  &--hidden {
    display: none;
  }

  &__icon {
    margin-right: 10px;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
  }
}
