@import 'common/styles/global';

.filter-dropdown-control {
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  overflow: visible;
  position: relative;

  &__value {
    align-items: center;
    border: 1px solid var(--color-Neutral-3);
    background-color: var(--color-Neutral-1);
    border-radius: 500px;
    color: var(--color-Neutral-6);
    cursor: pointer;
    display: flex;
    padding: 0 16px;
    height: 48px;
    transition: 100ms ease-out background-color;
    width: fit-content;
    &,
    span {
      white-space: nowrap;
    }

    &:hover {
      background-color: var(--color-Neutral-3);
    }
    @media screen and (max-width: $desktop) {
      justify-content: center;
    }
  }

  &__arrow {
    display: inline-block;
    width: 0;
    height: 0;
    border-left: 0.35em solid transparent;
    border-right: 0.35em solid transparent;
    border-top: 0.35em solid currentColor;
    margin-left: 1em;
    margin-right: -0.4em;
  }

  &--open &__value {
    border: 1px solid var(--color-primary-pure);
    background-color: unset;
  }

  &--open &__arrow {
    border-bottom: 0.35em solid currentColor;
    border-top: 0;
  }
  &--icon {
    padding: 0 4px;
    display: flex;
    align-items: center;
  }
  &--open &--icon {
    transform: rotateZ(180deg);
  }

  &--selected .filter-dropdown-control__value,
  &--open .filter-dropdown-control__value {
    background-color: var(--color-primary-pure);
    color: var(--color-contrast-01);
    border-color: var(--color-primary-pure);  
  }

  &__list {
    @extend %shadows--dragged;
    background-color: var(--color-Neutral-1);
    border: 1px solid var(--color-Neutral-3);
    border-radius: 16px;
    left: -4px;
    margin: 0;
    min-width: 270px;
    padding: 0;
    position: absolute;
    top: calc(32px + 16px);
    z-index: 15;
    max-height: 50vh;
    overflow: auto;
  }

  &__option {
    color: var(--color-Neutral-5);
    list-style: none;
    padding: 16px;
    cursor: pointer;
    transition: color 80ms ease-out;
    display: flex;
    gap: 4px;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
    line-height: 1;
    &:hover {
      color: var(--color-primary-pure);
      background-color: var(--color-primary-low);
    }

    &:last-child {
      border-bottom: 0;
      margin-bottom: 0;
    }
    &--tag {
      font-weight: 700;
      border-radius: 40px;
      padding: 10px 16px;
      text-align: center;
      font-size: 14px;
      background: $color-positive-low;
      color: #009446;
    }
  }
}
