@import 'common/styles/global';

.footer {
  color: white;
  background: #0f2834;
  padding: 50px 20px;
  @media screen and (max-width: $desktop) {
    padding: 32px 0px;
  }
  .button--google-play {
    width: 135px;
    height: 50px;
    padding: 0 27px 0 44px;
    border: 0;
    background-image: url('/landing/assets/google-play-badge.png');
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: contain;
    color: $color-white-transparent;
  }

  .button--app-store {
    width: 120px;
    height: 50px;
    padding: 0;
    border: 0;
    background-image: url('/landing/assets/app-store-badge.svg');
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: contain;
    color: $color-white-transparent;
  }

  &__container {
    margin-left: auto;
    margin-right: auto;
    padding-left: 24px;
    padding-right: 24px;

    max-width: 1192px;
  }

  &__buttons {
    display: flex;
    .button {
      height: 40px;
      margin-right: 1em;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__explore_about {
    display: inline-flex; 
    width: 100%;
    gap: 80px;
    justify-content: flex-end;
    @media screen and (max-width: $desktop) {
      display: flex;
      justify-content: flex-start;
      gap: 71px;
      margin-top: unset;
      width: unset;
    }
  }
  &__title {
    font-family: $font-main;
    font-size: 14px;
    color: #949494;
    font-weight: 400;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    padding-bottom: 16px;
    border-bottom: 1px solid $color-shade-outline;
    margin-bottom: 2em;
    .dropdown {
      color: var(--color-primary-pure);
    }
    span {
      white-space: nowrap;
    }
    @media screen and (max-width: $desktop) {
      border: none;
      margin: 0;
      padding: 0;
    }
  }
  p {
    font-weight: 300;
    font-size: 14px;
    line-height: 150%;
  }

  &__section--description {
    @media screen and (max-width: $desktop) {
      display: flex;
      flex-direction: column;
      max-width: unset !important;
      h4,
      p {
        text-align: center;
      }
    }
  }
  &__section {
    &--description {
      max-width: 40%;
    }
    h4 {
      @extend %heading-xl;
    }
  }
  hr {
    width: 100%;
  }
  &__Language {
    display: flex;
    padding: 12px 8px;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid #edefed;

    &:hover,
    &--open {
      background-color: var(--color-Neutral-2);
      color: var(--color-Neutral-6);
    }
    .filter-dropdown-control__value {
      height: unset;
    }
    .filter-dropdown-control__option {
      padding: 16px 24px;
    }

    .filter-dropdown-control__value {
      background-color: unset !important;
      color: unset !important;
      border: unset !important;
      box-shadow: unset !important;
      padding: unset;
      &:hover {
        background-color: unset !important;
      }
    }
    .filter-dropdown-control__list {
      top: 36px;
      left: -37px;
      border-radius: 16px;
      contain: content;
    }
  }
  &__link-list {
    padding: 0;
    margin: 0;
    li {
      a {
        padding: 8px;
        border-radius: 8px; 
        display: block;
      }
      a:hover {
        background: var(--color-primary-low);
        transition: 200ms;
        color: var(--color-primary-pure);
        font-weight: 700;
      }
    }
  }
  &__currencies_list {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    align-items: center;
    @media screen and (max-width: $desktop - 1) {
      justify-content: center;
      gap: 24px;
    }
  }
  &__link {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 14px;
    font-weight: 400; 
    @media screen and (max-width: $desktop - 1) {
      max-width: 240px;
    }
    a {
      transition: 60ms ease-in color;
      &:hover {
        color: var(--color-primary-pure);
      }
    }

    &:last-child {
      margin-bottom: 0;
      cursor: pointer;
    }
  }

  &__logo {
    opacity: 0.4;
    margin-bottom: 2em;
  }

  &__about {
    margin-top: 2em;
  }

  &__social {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 12px;
    position: relative;

    @media screen and (max-width: $desktop) {
      a {
        text-align: center;
        display: flex;
      }
    }
    &__twitter,
    &__instagram,
    &__medium,
    &__discord,
    &__youtube,
    &__facebook {
      color: var(--color-Neutral-1);
    }
  }

  &__disclaimer {
    margin-top: 1em;
    font-size: 12px;
    color: #97a4aa;
    line-height: 1.4;
    max-width: 80%;
  }
  hr {
    margin: 24px 0;
  }
  &__verification {
    @media screen and (max-width: $desktop) {
      margin: 0;
      align-items: center;
      justify-content: center;
      gap: 8px;
    }
  }
  &__sitemap--currencies {
    @media screen and (max-width: $desktop - 1) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 24px;
    }
  }
  // &__curacao {
  //   margin-left: -8px;
  //   margin-right: 15px;
  // }

  &__verification,
  &__powered-by {
    display: flex;
    align-items: center;
    gap: 8px;
    @media screen and (max-width: $desktop - 1) {
      gap: 24px;
    }
  }

  &__company {
    @media screen and (max-width: $desktop - 1) {
      display: flex;
      justify-content: center;
    }
  }
  &__skrill-icon {
    margin-right: 25px;
  }

  &__currencies {
    @media screen and (max-width: $desktop - 1) {
      display: grid;
      justify-items: center;
    }
  }
  &__currency {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  &__bitcoin-circle {
    margin-top: 10px;
    width: 49px;
    height: 49px;
    margin-left: 10px;
  }

  &__language {
    > div {
      color: var(--color-primary-pure);
    }
  }

  &__sitemap--signatures {
    @media screen and (max-width: $desktop - 1) {
      display: flex;
      justify-content: space-between;
    }
  }
  @media screen and (max-width: $desktop - 1) {
    &__logo {
      display: none;
    }

    &__social,
    &__buttons {
      justify-content: center;
      max-width: 309px;
      gap: 24px;
    }

    &__buttons {
      margin-top: 20px;
    }
  }

  &__sitemap {
    margin-top: 32px;
  }
  &__about,
  &__sitemap {
    display: flex;
    justify-content: space-between;
  }

  @media screen and (min-width: $desktop) {
    padding-left: 0;
    padding-right: 0;

    &__logo {
      margin-bottom: 0;
    }

    &__about {
      border-top: 1px solid rgba(#b9c4ca, 0.2);
      margin-top: 4em;
      padding: 2.5em 0;
      grid-template-columns: 2fr 1fr;
    }

    &__verification {
      padding-top: 0;
    }

    &__verification,
    &__disclaimer {
      margin-top: 0;
    }

    &__powered-by {
      align-items: flex-start;
    }

    &__powered-by {
      padding-top: 11px;
    }

    &__neteller-icon {
      margin-top: 9px;
    }
  }
  &__powered-by {
    display: none;
  }
}
