@import 'common/styles/grid-config';
@import 'common/styles/global';
@import 'common/styles/zindex';
@import 'common/styles/common';
@import 'common/styles/theme';

.app {
  background-color: var(--color-Neutral-1);
  color: var(--color-Neutral-6);

  width: 100%;

  &__modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    z-index: z-index(modal);
  }

  &__loading {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
  }

  &--noscroll {
    overflow: hidden;
  }

  .view-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    overflow: hidden;
  }

  @media (min-width: $tablet-wide) {
    .view-container {
      width: 100%;
    }
  }
}
