@import 'common/styles/global';

.view-activity {
  .notification-list {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 100%;

    @media screen and (max-width: $desktop) {
      padding: 24px;
    }
    .notification-item {
      &:last-of-type {
        border-bottom: 0;
      }
    }
    &--empty {
      display: grid;
      justify-items: center;
      align-items: center;
      height: calc(100vh - 130px);
      align-content: center;
      i {
        display: flex;
        width: 48px;
        height: 48px;
        padding: 12px;
        justify-content: center;
        align-items: center;
        border-radius: 34px;
        background: var(--color-primary-pure);
        margin-bottom: 16px;
      }
    }
  }

  @media screen and (min-width: $tablet-wide) {
    .notification-list__section {
      width: 100%;
    }
  }
}

.notification-item {
  padding: 32px 16px;
  display: grid;
  grid-template-columns: 72px auto;
  grid-gap: 16px;
  align-items: center;

  .notification-image {
    width: 100px;
    height: 80px;
    border-radius: 4px;
    flex: 0 0 100px;
    margin-right: 10px;

    &--round {
      width: 72px;
      height: 72px;
      border-radius: 50%;
      flex: 0 0 60px;
    }
  }

  &__time {
    color: rgba($color-black, 0.4);
  }
}
