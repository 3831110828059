@import 'common/styles/global';

.accordion {
  cursor: pointer;
  &__title {
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    h6 {
      @extend %heading-xs;
      color: var(--color-Neutral-4);
      max-width: calc(100% - 25px);
      @media screen and (max-width: $desktop) {
        font-size: 16px;
      }
    }
  }
  &__description {
    overflow: hidden;
    transition: 200ms;
    p {
      @extend %paragraph-sm;
      color: var(--color-Neutral-4);
    }
  }

}
