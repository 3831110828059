@import 'common/styles/global';

.kyc-callout {
  cursor: auto;
  display: flex;
  font-size: 1em;
  justify-content: center;
  left: auto;
  margin: 0;
  top: 70px;

  width: 100%;

  .tooltip__content {
    width: 100%;
    padding-bottom: 1.5em;
  }

  &__title {
    margin-top: 0;
  }

  &__arrow {
    left: auto;
    right: 52px;
  }

  &__footer {
    display: flex;
    margin-top: 2em;
  }

  @media screen and (min-width: $tablet) {
    max-width: 360px;
    right: 45px;
    top: 65px;

    &__arrow {
      right: 35px;
    }
  }
}