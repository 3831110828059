@import '../../common/styles/global';

.tabbar {
  // @include overscroll-behavior(contain);
  //@include glass-effect;

  position: fixed;
  right: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  bottom: 0;
  left: 0;
  display: flex;
  width: 100%;
  flex-flow: row nowrap;
  align-items: stretch;
  justify-content: space-between;
  padding: 0 $toolbar-padding;
  background: var(--color-Neutral-1);
  z-index: 100;
  box-shadow: 0px 0 5px rgba(0, 0, 0, 0.05);
  transition: transition(500ms $notification-easing, transform);

  &--hide {
    position: fixed;
    transform: translateY(100%);
  }

  @supports (padding: unquote('env(safe-area-inset-top)')) {
    padding-right: unquote('env(safe-area-inset-right)');
    padding-bottom: unquote('env(safe-area-inset-bottom)');
    padding-left: unquote('env(safe-area-inset-left)');
  }

  &__home-button .tabbar-button__icon {
    background: var(--color-Neutral-3);
  }

  .tabbar-button {
    color: var(--color-Neutral-4); 
    display: flex;
    height: $tabbar-height;
    flex-basis: 0; // Distribute all space equally
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    gap: 11px;
    padding: 4px 0;
    position: relative;
    outline: 0;
    font-size: 14px;
  }

  .tabbar-button--active {
    color: var(--color-primary-pure);
    font-weight: 700;

    .tabbar-button__icon {
      color: var(--color-primary-pure);
    }
  }

  .tabbar-button__icon {
    display: flex;
    position: relative;
  }

  .tabbar-button__badge {
    position: absolute;
    top: 32%;
    right: calc(50% - 8px);
    background-color: $color-red;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    outline: 2px solid var(--color-Neutral-1);
  }

  .tabbar-button__notification-warning {
    position: absolute;
    width: 8px;
    height: 8px;
    border: 2px solid var(--color-Neutral-1);
    box-sizing: content-box;
    border-radius: 50%;
    right: -2px;
    top: -3px;
    background-color: $color-error;
  }

  &__web-version {
    position: fixed;
    bottom: 78px;
    width: 100%;
    z-index: 99;
    transition: transition(500ms $notification-easing, transform);

    &--hide {
      transform: translateY(50px);
    }
  }

  @media screen and (min-width: $desktop) {
    display: none;

    &__web-version {
      bottom: 0;
      &--hide {
        transform: translateY(0);
      }
    }
  }
}

@media (max-width: $desktop - 1) {
  // on commit 088656e57 2018/10/8 we had set this to position: unset;
  // this has caused the component to float on the bottom of the screen
  // today we changed to fixed. Probably it will break somewhere else.
  // This probably breaks flexbox.
  .tabbar {
    height: 78px;
    flex: 0 0 78px;
  }
}

@media (max-height: 400px) {
  .tabbar {
    flex-shrink: 1;
  }
}
