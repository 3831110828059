@import 'common/styles/global';
@import 'common/styles/zindex';

.search-widget {
  display: grid;
  padding: 24px;
  gap: 32px;
  justify-items: flex-start;
  background-color: var(--color-Neutral-1);
  position: absolute;
  left: 0;
  top: 70px;
  width: 100%;
  box-shadow: 0px 8px 24px 0px #00000029;
  border: 1px solid var(--color-Neutral-3);
  z-index: 200;
  border-radius: 16px;
  max-height: 80vh;
  overflow: auto;
}
.search-results {
  display: grid;
  gap: 32px;

  &__empty {
    display: grid;
    width: 100%;
    justify-content: center;
    justify-items: center;
    gap: 16px;
    &--icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 64px;
      height: 64px;
      background: var(--color-Neutral-2);
      border-radius: 500px;
    }
    p {
      color: var(--color-Neutral-4);
      @extend %paragraph-sm;
    }
    a {
      color: var(--color-primary-pure);
      @extend %label-lg;
    }
  }
  &__block {
    width: 100%;

    &--row {
      display: flex;
      justify-content: space-between;
      &--lables {
        color: var(--color-Neutral-4);
        font-family: Lato;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
      }
      a {
        color: var(--color-primary-pure);
        font-weight: 600;
        &:hover{
          color: var(--color-primary-high);

        }
      }
    }

    &--markets {
      display: grid;
      gap: 24px;
      &__title {
        @extend %heading-xxs;
        color: var(--color-Neutral-5);
      }
    }
    &--tags {
      &--list {
        display: flex;
        flex-wrap: wrap;
        gap: 16px 0;
      }
    }
    &--tags,
    &--markets,
    &--categories,
    &--users {
      margin-top: 16px;
    }
  }
}
.navbar__search .lazyload-placeholder{
  display:none !important;
}
