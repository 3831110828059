@import 'common/styles/global';

.notification_widget {
  &__container {
    position: absolute;
    background: var(--color-Neutral-1);
    border: 1px solid var(--color-Neutral-3);
    border-radius: 16px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    pointer-events: none;
    opacity: 0;
    right: 0;

    @media screen and (min-width: $desktop) {
      transform: scaleY(0);
      top: -200px;
      width: 50px;
    }
    &--open {
      top: 63px;
      width: 628px;
      z-index: 5;
      opacity: 1;
      pointer-events: all;
      transform: scaleY(1);
    }
    &--scroll {
      position: relative;
      overflow: hidden;
      max-height: 656px;
      min-height: 232px;
      display: flex;
    }
    // .infinite-grid__results {
    //   position: relative;
    //   height: 500px;
    //   overflow-y: scroll;
    //   -webkit-overflow-scrolling: touch;
    // }
  }
}
