@import 'common/styles/global';

@keyframes popUpPlaceBet {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0%);
  }
}
@keyframes popDownPlaceBet {
  from {
    transform: translateY(0%);
  }
  to {
    transform: translateY(100%);
  }
}
.view-feed {
  @extend %full-page-height;
  display: inline-block;
  width: 100%;

  &:not(&__loading) {
    background-color: var(--color-surface-1);
  }

  &__top {
    background-color: var(--color-surface-1);
    padding: 0;
  }

  &__bitcoin-banner {
    margin-bottom: 0;
    margin-top: 16px;
  }

  &__banner {
    padding: 0px 0 24px;
    @media screen and (min-width: 712px) {
      padding-top: 40px;
      padding-bottom: 64px;
    }
  }

  &--authenticated &__currency-list {
    display: none;
  }

  @media screen and (min-width: 712px) {
    &__bitcoin-banner {
      margin-top: 0;
    }

    &--authenticated &__top {
      background-color: transparent;
    }
  }

  @media screen and (min-width: $tablet-wide) {
    &__bitcoin-banner {
      margin-bottom: 30px;
    }

    &__top {
      padding: 0;
    }
  }

  @media screen and (min-width: 1200px) {
    .infinite-grid__content {
      gap: 24px;
    }
  }
  &__aside {
    display: flex;
    flex-direction: column;
    max-width: 360px;
    position: sticky;
    top: 126px;
    &--closed {
      animation: popDownPlaceBet 200ms forwards ease-out;

      // position: fixed;
      // bottom: 0;
      // z-index: 101;
      // top: unset;
      // width: 100%;
      // max-width: unset;
      .place-bet__header {
        svg {
          transform: rotate(0);
        }
      }
      z-index: 99;
      // .place-bet__content {
      //   opacity: 0;
      //   padding: 0;
      //   height: 0px;
      //   transition: height 500ms cubic-bezier(0.005, 0.75, 0.25, 1);
      //   transition: opacity 500ms cubic-bezier(0.005, 0.75, 0.25, 1);
      // }
      .place-bet__header {
        svg {
          transition: 500ms cubic-bezier(0.005, 0.75, 0.25, 1);

          transform: rotate(180deg);
        }
      }
      @media screen and (max-width: $desktop) {
        &--tabBarUp {
          .place-bet__content {
            height: 78px;
          }
        }
      }
    }
  }

  @media screen and (min-width: $desktop) {
    display: block;

    &__slider {
      margin-top: 0;
      margin-bottom: 10px;
    }

    &__bitcoin-banner {
      margin-top: 12px;
      margin-bottom: 0;
    }
  }
  &--place_bet {
    display: flex;
    justify-content: flex-end;
    position: fixed;
    right: 80px;
    bottom: 0;
    z-index: 2;
    &:not(.view-feed__aside--closed) {
      animation: popUpPlaceBet 200ms forwards ease-out;
    }
    @media screen and (max-width: $desktop) {
      position: fixed;
      bottom: 0;
      left: 0;
      // background: white;
      z-index: 102;
      top: unset;
      width: 100%;
      border-radius: 8px 8px 0 0;
      background: var(--color-Neutral-1);
      max-width: unset;
      .place-bet__header {
        svg {
          transform: rotate(0);
        }
      }
      &--closed {
        z-index: 99;
        .place-bet__header {
          svg {
            transition: 500ms cubic-bezier(0.005, 0.75, 0.25, 1);

            transform: rotate(180deg);
          }
        }
        &--tabBarUp {
          .place-bet__content {
            height: 78px;
          }
        }
      }
    }
  }
}
