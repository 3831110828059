@import 'common/styles/global';

.go-to-web-version {
  background: var(--color-Neutral-1);
  color: $color-shade-1;
  font-size: 14px;
  padding: 16px 0;

  &__futuur-logo-container {
    align-items: center;
    border-radius: 4px;
    border: 1px solid $color-shade-3;
    color: var(--color-primary-pure);
    display: flex;
    font-size: 40px;
    height: 70px;
    justify-content: center;
    width: 70px;
  }

  &__container {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: 70px auto;
    margin: 0 auto;
    max-width: 600px;
    padding: 0 10px;
    position: relative;
  }

  &__title {
    margin: 0.5em 0 0;
  }

  &__close {
    color: var(--color-primary-pure);
    cursor: pointer;
    font-size: 12px;
    position: absolute;
    right: 10px;
    top: 8px;

    &::before {
      content: "";
      height: 20px;
      left: -5px;
      position: absolute;
      top: -5px;
      width: 20px;
    }
  }

  p {
    margin: 0.5em 0 1em;
  }

  button {
    padding: 0;
  }
}
