@import 'common/styles/global';

.button-main {
  background-color: transparent;
  border-radius: 8px;
  color: $on-action-label-primary-color;
  cursor: pointer;
  border: 0;
  padding: 0 2em;
  display: flex;
  align-items: center;
  font-weight: 600;
  justify-content: center;
  transition: background-color 200ms ease-in; 
  white-space: nowrap;
  &--size-small {
    font-size: 12px;
    height: 32px;
  }

  &--size-medium {
    font-size: 14px;
    font-family: $font-main;
    height: 32px;
  }

  &--size-large,
  &--size-full {
    height: 56px;
    font-size: 16px;
    font-weight: 6900;
    padding-right: 40px;
    padding-left: 40px;
    font-family: $font-main;
  }

  &--size-full {
    width: 100%;
  }

  &--type-discreet {
    height: 32px;
    font-family: $font-main;
    letter-spacing: 1.25px;
    font-weight: 400;
    color: var(--action-background-primary-color);
  }

  // Solid:
  &--type-solid.button-main--variant-primary {
    background-color: var(--action-background-primary-color);
    color: var(--color-contrast-01);

    &:hover {
      border-color: var(--color-brand-05);
      background-color: var(--color-brand-05);
      color: white;
    }
    &:disabled {
      background-color: rgba($color-grey3, 0.4);
      color: $color-grey3;
      pointer-events: none;
      border: none;
    }
  }

  &--type-solid.button-main--variant-negative {
    background-color: var(--color-negative-4);
    color: var(--color-Neutral-1);

    &:hover {
      border-color: var(--color-negative-3);
      background-color: var(--color-negative-3);
      color: white;
    }
    &:disabled {
      background-color: rgba($color-grey3, 0.4);
      color: $color-grey3;
      pointer-events: none;
      border: none;
    }
  }

  &--type-solid.button-main--variant-shade {
    background-color: $color-grey1;
    color: $color-grey4;

    &:hover {
      background-color: $color-grey0;
    }
  }

  &--type-solid.button-main--variant-white {
    background-color: var(--color-Neutral-1);
    color: var(--action-background-primary-color);
    border-color: var(--color-Neutral-1);

    &:hover {
      background-color: var(--color-Neutral-1);
      color: $color-primary-shade-5;
    }
  }

  // Discreet
  &--type-discreet.button-main--variant-primary {
    &:hover {
      background-color: var(--action-background-primary-color);
      color: var(--color-Neutral-1);
    }
  }

  &--type-discreet.button-main--variant-negative {
    &:hover {
      background-color: var(--color-negative-4);
      color: var(--color-Neutral-1);
    }
  }

  &--type-discreet.button-main--variant-shade {
    color: $color-shade-2;
    background-color: $color-grey0;

    &:hover {
      background-color: var(--color-Neutral-1);
    }
  }

  // Text only:
  &--type-text-only.button-main--variant-primary {
    color: var(--action-background-primary-color);
    background-color: transparent;

    &:hover {
      background-color: $color-primary-hover;
    }

    &:focus {
      background-color: $color-primary-focus;
    }
  }

  &--type-text-only.button-main--variant-negative {
    color: var(--color-negative-4);
    background-color: transparent;

    &:hover {
      background-color: var(--color-negative-3);
    }

    &:focus {
      background-color: var(--color-negative-1);
    }
  }

  &--type-text-only.button-main--variant-shade {
    color: $color-grey4;
    background-color: transparent;

    &:hover {
      background-color: $color-grey0;
    }

    &:focus {
      background-color: $color-grey1;
    }
  }

  // Outlined:
  &--type-outlined {
    border: 1px solid currentColor;
  }

  &--type-outlined.button-main--variant-primary {
    color: var(--action-background-primary-color);

    &:hover {
      border-color: transparent;
      background-color: var(--color-brand-05);
      // color: var(--color-contrast-01);
      color: white;
    }

    &:focus {
      background-color: $color-primary-focus;
    }
  }

  &--type-outlined.button-main--variant-negative {
    color: var(--color-negative-4);

    &:hover {
      border-color: transparent;
      background-color: var(--color-negative-3);
      // color: var(--color-contrast-01);
      color: white;
    }

    &:focus {
      background-color: var(--color-negative-1);
    }
  }

  &--type-outlined.button-main--variant-shade {
    color: var(--color-Neutral-6);
    border-color: $color-shade-3;

    &:hover {
      background-color: var(--color-Neutral-1);
    }

    &:focus {
      background-color: var(--color-Neutral-3);
    }
  }

  &:hover {
    background-color: var(--color-primary-pure);
  }

  &:disabled {
    background-color: var(--color-Neutral-1);
    border: 1px solid currentColor;
    box-shadow: none;
    color: rgba($color-grey3, 0.4);
    cursor: auto;

    &:hover {
      background-color: var(--color-Neutral-1);
    }
  }

  &:focus {
    outline: 0;
  }
}
