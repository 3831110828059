@import 'common/styles/global';

.onboarding-tour {
  max-width: 330px;

  &__logo {
    font-size: 12px;
    color: var(--color-primary-low);
    display: flex;
    justify-content: center;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid $color-shade-4;
    margin-bottom: 32px;

    .button-main {
      padding: 0;
    }
  }

  &__ooms-icon {
    font-size: 42px;
    display: flex;
    justify-content: center;
  }

  &__add-funds,
  &__find-market,
  &__buy-and-sell {
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    display: flex;
    height: 40px;
    width: 100%;
  }

  &__add-funds {
    background-image: url('../assets/onboarding-currencies.png');
    margin-bottom: 0.5em;
  }

  &__find-market {
    background-image: url('../assets/find-market.png');
  }

  &__buy-and-sell {
    background-image: url('../assets/buy-and-sell.png');
  }

  &__header-counter {
    font-size: 12px;
    font-weight: 500;
  }

  &__slide {
    text-align: center;
    margin-bottom: 64px;

    h2 {
      margin: 1em auto 1.25em;
      max-width: 200px;
    }

    p {
      margin: 1em 0;
    }
  }
}
