@import 'common/styles/global';

.navbar-menu__spacer {
  margin-bottom: 24px;
  margin-top: 20px;
  border-bottom: 1px solid var(--color-Neutral-3);
}

.navbar-menu__dots {
  display: flex;
}
.navbar-menu {
  &__myProfile {
    display: flex;
    gap: 24px;
    align-items: center;
    padding: 24px 16px;
    cursor: pointer;
 
    &__avatar {
      width: 96px;
      height: 96px;
      border-radius: 100%;
      object-fit: cover;
    }
    &__name {
      @extend %heading-xs;
      font-weight: 600;
      color: var(--color-Neutral-6);
    }
    &__username {
      @extend %label-md; 
      color: var(--color-Neutral-4);
    }
    &__link{
      margin-top: 24px;
    }
  }
}
@media (min-width: $tablet-wide) {
  .navbar .dropdown-menu--open {
    .dropdown-menu__item-list {
      width: 350px;
    }
  }
}
